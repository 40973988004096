import React, { FC } from "react";

import ContactPage from "../../../components/pages/OilFinder/Contact";
import { PageProps } from "gatsby";

const SchmierstoffeContactPage: FC<PageProps<any>> = ({
    location: { search },
}) => {
    const searchParams = new URLSearchParams(search);
    const productNumber = Number(searchParams.get("productNumber"));
    const quantity = Number(searchParams.get("quantity"));

    return <ContactPage {...{ productNumber, quantity }} />;
};

export default SchmierstoffeContactPage;
